<template>
  <div>
    <div class="overmask" :style="returnaCor('alphaLogin', false, true)"></div>
    <div class="container" id="Login">
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <b-row class="mt-5 text-center" v-if="erroConexao != ''">
        <b-col>
          <img :src="logo" style="max-width: 90%" />
          <div class="alert alert-warning">{{ erroConexao }}</div>
          <b-button @click="buscaDadosEmpresa()">Tentar Novamente</b-button>
        </b-col>
      </b-row>
      <div v-else class="mt-5">
        <div style="padding: 15% 0"></div>
        <div>
          <b-col class="text-center">
            <div class="text-white" style="margin-top: ">
              <h4 class="text-uppercase text-shadow">Trocar Senha</h4>
              <div class="alert alert-warning my-3" v-if="form.dadosRes">
                Identificamos que você ainda não trocou sua senha, altere agora
                para sua segurança.
              </div>
            </div>
            <!-- <b-form-group id="input-group-2" label-for="input-2">
              <password
                v-model="form.senha"
                id="input-2"
                name="Senha"
                placeholder="Nova Senha"
                :secureLength="5"
                :badge="false"
                v-validate="{ required: true }"
                :state="validateState('Senha')"
                :toggle="true"
                @score="showScore"
              />
            </b-form-group> -->
            <b-form-input
              :type="`${tipoInputSenha}`"
              id="Senha"
              v-model="form.senha"
              name="Senha"
              placeholder="Senha"
              v-validate.initial="{ required: true }"
              :state="validateState('Senha')"
            ></b-form-input>
            <b-button
              type="submit"
              class="btn-block mt-3"
              variant="dark"
              :style="returnaCor('botaoLogin', true, false)"
              @click="atualizarSenha()"
              >Salvar</b-button
            >
          </b-col>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import locale from '../locale';
import service from '../services';
import jwt from 'jsonwebtoken';
import Password from 'vue-password-strength-meter';

import colors from './../services/colors';
export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      form: {
        codigoVerificacao: null,
      },
      score: 0,
      erroConexao: '',
      carregando: false,
      logo: 'https://bboneapp.s3.amazonaws.com/evo-gerencial-color.png',
      termo: '<p></p>',
      exibeTermo: false,
      value: 80,
      max: 100,
      nomeEmpresa: null,
      emailEmpresa: null,
      temaCor: {
        botaoLogin: '#000000',
        alphaLogin: '#000000',
        botaoFormularios: '#000000',
      },
      tipoInputSenha: 'password',
    };
  },
  components: { Password },
  methods: {
    returnaCor(chave, comSombra, backgroundTransparent) {
      var rgb = {};
      if (this.temaCor[chave]) rgb = colors.hexToRgb(this.temaCor[chave]);

      if (backgroundTransparent)
        return `background-color: rgba(${rgb.r}, ${rgb.g}, ${rgb.b},0.3);`;
      if (comSombra)
        return `
      box-shadow: 0 5px 15px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9);
      background-color: ${this.temaCor[chave]};
      `;
      else
        return `
      background-color: ${this.temaCor[chave]};
      `;
    },
    showScore(score) {
      this.score = score;
    },
    selecionaTab(valor) {
      this.form.formSelecionado = valor;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    atualizarSenha(evt) {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        if (this.form.senha.length < 5) {
          this.$bvToast.toast('Sua senha tem que ter no minimo 5 caracteres', {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });
          return;
        }

        this.carregando = true;
        service
          .post('Login', 'TrocarSenhaAssociado', this.form)
          .then((res) => {
            this.carregando = false;
            console.log(res.data);
            if (res.data.token) {
              localStorage.setItem('user-token', res.data.token);
              var decoded = jwt.decode(res.data.token);
            }
            this.$router.push({
              path: res.data.redirect,
            });
          })
          .catch((e) => {
            this.carregando = false;
            this.$bvToast.toast(e, {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      });
    },
    buscaDadosEmpresa() {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        this.logo = localStorage.getItem('logo');
        const token = localStorage.getItem('user-token');

        var dadosRes = localStorage.getItem('dadosRes');
        if (dadosRes) dadosRes = JSON.parse(dadosRes);
        this.form.idEmpresa = dadosRes.id_empresa;
        this.form.dadosRes = dadosRes;

        var temaCor = localStorage.getItem('temaCor');
        var tema = JSON.parse(temaCor);
        this.temaCor = tema;

        this.carregando = false;
        console.log(this.form.idEmpresa);
      });
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
    this.buscaDadosEmpresa();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}

.logoRodape {
  text-align: center;
  margin-top: 15px;
  font-size: 10px;
  display: block;
}
.link-senha {
  font-size: 13px;
  text-decoration: underline;
}

body {
  background-image: url('./../assets/images/3.png');
}
</style>
