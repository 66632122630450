export default {
  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  },
  getColorsTema() {
    var temaCor = localStorage.getItem('temaCor');
    let tema = {};
    if (temaCor) {
      tema = JSON.parse(temaCor);
    }
    return tema;
  },
};
